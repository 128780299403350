import React from "react";

import {
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
  StudentAssessmentHeader,
} from "../StudentAssessment";
import ABCBlocks from "assets/icons/abc-blocks.svg";
import CompleteAssessmentForm from "./CompleteAssessmentForm";

const typeToTitleMap = {
  StudentLetterNamesAndSoundsCard: "Letter Names and Sounds",
  StudentSoundAndBlendCard: "Sound and Blend",
  StudentDecodingByAnalogyCard: "Decoding by Analogy",
  StudentWordDecodingCard: "Word Decoding",
  StudentTrackingDecodableTextCard: "Tracking Decodable Text",
  StudentSpellingCard: "Spelling",
};
const typeToIdMap = {
  StudentLetterNamesAndSoundsCard: "student-letter-names-and-sounds-card",
  StudentSoundAndBlendCard: "student-sound-and-blend-card",
  StudentDecodingByAnalogyCard: "student-decoding-by-analogy-card",
  StudentWordDecodingCard: "student-word-decoding-card",
  StudentTrackingDecodableTextCard: "student-tracking-decodable-text-card",
  StudentSpellingCard: "student-spelling-card",
};

const typeToDescriptionMap = {
  StudentLetterNamesAndSoundsCard: [
    <p className="mb-6 bg-brand-50 p-10 rounded-lg" key="paragraph-1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </p>,
  ],
  StudentSoundAndBlendCard: [
    <p className="mb-6 bg-brand-50 p-10 rounded-lg" key="paragraph-1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </p>,
  ],
  StudentDecodingByAnalogyCard: [
    <p className="mb-6 bg-brand-50 p-10 rounded-lg" key="paragraph-1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </p>,
  ],
  StudentWordDecodingCard: [
    <p className="mb-6 bg-brand-50 p-10 rounded-lg" key="paragraph-1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </p>,
  ],
  StudentTrackingDecodableTextCard: [
    <p className="mb-6 bg-brand-50 p-10 rounded-lg" key="paragraph-1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </p>,
  ],
  StudentSpellingCard: [
    <p className="mb-6 bg-brand-50 p-10 rounded-lg" key="paragraph-1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </p>,
  ],
};

const StudentPlaceholderLessonCard = (props) => (
  <StudentAssessment
    {...props}
    data-testid={typeToIdMap[props.type]}
    queryKey={[typeToIdMap[props.type], props.assessmentId]}
  >
    <StudentAssessmentHeader
      timerSecondsRemaining={props.timerSecondsRemaining}
      displayTimer={props.displayTimer}
      assessmentDuration={props.durationSeconds}
      assessmentName={typeToTitleMap[props.type]}
      icon={<ABCBlocks />}
    />
    <StudentAssessmentContentExpanded>
      <div className="flex flex-col xl:flex-row-reverse justify-between space-y-6 xl:space-y-0">
        <div className="grow-0 shrink xl:basis-[350px] xl:pl-6 border-zinc-200 sticky">
          <div className="xl:px-5 pt-6 xl:pb-8 flex-col">
            <CompleteAssessmentForm />
          </div>
        </div>
        <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 max-w-[80rem] relative">
          <div className="flex flex-col">
            <div>{typeToDescriptionMap[props.type]}</div>
          </div>
        </div>
      </div>
    </StudentAssessmentContentExpanded>
    <StudentAssessmentContentCollapsed></StudentAssessmentContentCollapsed>
  </StudentAssessment>
);
export default StudentPlaceholderLessonCard;
