import React from "react";

import Skeleton from "../common/Skeleton";
import CreateNoteModal from "../StudentNotes/CreateNoteModal";
import HeaderField from "./HeaderField";
import HFWMasteryField from "./HFWMasteryField";
import { useStudentQuery } from "./queries";
import SpecialEducationField from "./SpecialEducationField";
import { useStudentsQuery } from "../TutorStudentDashboard/queries";
import StudentPicker from "./StudentPicker";
import JoinSessionButton from "../JoinSessionButton";

const Header = () => {
  const studentQuery = useStudentQuery();
  const studentsQuery = useStudentsQuery(false);

  if (!studentQuery.data && studentQuery.isError) {
    return (
      <span className="flex items-center justify-center text-red-600 min-h-[200px]">
        Error: {studentQuery.error.message}
      </span>
    );
  }

  const { clientURL, displayName, hasMultipleProgramEnrollments, nsbEnabled, isTutor } =
    studentQuery.data || {};

  return [
    <div className="bg-white sticky top-[55px] md:top-[61px] lg:top-0 border-b z-40" key="top-bar">
      <div className="flex items-center max-w-7xl mx-auto w-full px-4 md:px-6 lg:px-8 py-4">
        {studentQuery.data && studentsQuery.data ? (
          [
            <h3
              className="flex items-center md:text-xl lg:text-2xl font-semibold text-gray-900 flex-grow whitespace-nowrap truncate"
              data-heap-redact-text
              key="student-name"
            >
              {studentsQuery.data.students?.length > 0 ? (
                <StudentPicker students={studentsQuery.data.students} />
              ) : (
                <span data-testid="student-name">
                  {displayName}
                  {hasMultipleProgramEnrollments && (
                    <span
                      data-testid="multiple-enrollments"
                      className="rounded-full px-2.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 ml-2"
                    >
                      Accelerator Pack
                    </span>
                  )}
                </span>
              )}
            </h3>,
            <div className="flex items-center space-x-3" key="actions">
              {nsbEnabled && isTutor ? <JoinSessionButton clientURL={clientURL} /> : null}
              <CreateNoteModal />
            </div>,
          ]
        ) : (
          <div className="flex-grow">
            <Skeleton width="320" height="32" className="py-[2px]">
              <Skeleton.Rect x="0" y="0" width="320" height="28" rx="3" />
            </Skeleton>
          </div>
        )}
      </div>
    </div>,
    <div
      className="max-w-7xl mx-auto w-full px-4 md:px-6 lg:px-8 pt-3 md:pt-6 pb-3 md:pb-1"
      key="header"
    >
      <dl className="grid grid-cols-1 gap-y-3 lg:gap-x-4 lg:gap-y-4 sm:grid-cols-4 text-xs lg:text-sm">
        <HeaderField data-testid="header-field-school" label="School">
          {(data) => data.schoolName}
        </HeaderField>
        <HeaderField data-testid="header-field-grade-level" label="Grade Level">
          {(data) => data.gradeLevel}
        </HeaderField>
        <HeaderField data-testid="header-field-current-content" label="Current Content">
          {(data) => data.currentContent}
        </HeaderField>
        <HeaderField
          data-testid="header-field-english-language-learner"
          label="English Language Learner"
        >
          {(data) => (data.englishLanguageLearner ? "Yes" : "No")}
        </HeaderField>
        <HeaderField
          data-testid="header-field-language-spoken-at-home"
          label="Language Spoken at Home"
        >
          {(data) => data.languageSpokenAtHome}
        </HeaderField>
        <HeaderField data-testid="header-field-iep-504-status" label="IEP/504 Status">
          <SpecialEducationField />
        </HeaderField>
        <HeaderField
          data-testid="header-field-hfw-mastery"
          label="Current HFW Mastery"
          skeleton={
            <Skeleton height="48" width="170">
              <Skeleton.Rect x="0" y="0" width="170" height="4" rx="2" />
              <Skeleton.Rect x="0" y="10" width="80" height="14" rx="3" />
            </Skeleton>
          }
        >
          <div className="min-h-[48px]">
            <HFWMasteryField />
          </div>
        </HeaderField>
      </dl>
    </div>,
  ];
};

export default Header;
