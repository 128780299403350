import React, { useMemo } from "react";
import Item from "./Item";
import PropTypes from "prop-types";
import { readingAssessmentTaskType as READING_ASSESSMENT_TASK_TYPE } from "../../../constants";
import { parseTaskItems } from "./utils";
import cx from "classnames";

export default function List({
  "data-testid": dataTestId = "xTaskList",
  className,
  isLoading,
  onComplete,
  onSelect,
  selectedTaskId,
  tasks = [],
  tooltips = [],
}) {
  const parsedTasks = useMemo(() => parseTaskItems(tasks, tooltips), [tasks, tooltips]);
  const isListDisabled = useMemo(() => tasks.every(({ completed }) => !!completed), [tasks]);

  return (
    <ul
      data-testid={dataTestId}
      className={cx(
        "border border-gray-200 rounded-lg bg-white divide-y divide-gray-200 px-3",
        className
      )}
    >
      {parsedTasks.map(
        ({
          correctWordsCount,
          completed,
          content,
          endItemId,
          hasResults,
          id,
          isCurrent,
          isDisabled,
          isReady,
          task,
          title,
          tooltip,
        }) => {
          return (
            <Item
              correctWordsCount={correctWordsCount}
              content={content}
              endItemId={endItemId}
              data-testid={dataTestId}
              hasResults={hasResults}
              id={id}
              isCompleted={completed}
              isCurrent={isCurrent}
              isDisabled={isDisabled}
              isListDisabled={isListDisabled}
              isLoading={isLoading}
              isReady={isReady}
              isSelected={id === selectedTaskId}
              key={id}
              onComplete={onComplete}
              onSelect={onSelect}
              task={task}
              title={title}
              tooltip={tooltip}
            />
          );
        }
      )}
    </ul>
  );
}

List.propTypes = {
  "data-testid": PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      attemptedWordsCount: PropTypes.number.isRequired,
      completed: PropTypes.bool.isRequired,
      content: PropTypes.string,
      correctWordsCount: PropTypes.number.isRequired,
      endItemId: PropTypes.number,
      id: PropTypes.number.isRequired,
      readingAssessmentItems: PropTypes.array,
      sequenceNumber: PropTypes.number.isRequired,
      studentReadingAssessmentId: PropTypes.number.isRequired,
      task: PropTypes.string.isRequired,
      taskType: PropTypes.oneOf([
        READING_ASSESSMENT_TASK_TYPE.CHECKBOX,
        READING_ASSESSMENT_TASK_TYPE.ONE_MINUTE_CHALLENGE,
        READING_ASSESSMENT_TASK_TYPE.QUESTIONS,
      ]).isRequired,
    }).isRequired
  ),
  tooltips: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
};
