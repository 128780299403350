import React from "react";
import { useStudentHighFrequencyWordsAssessmentResultsDrawerQuery } from "./queries";

export default function TabHFWAssessment({ id }) {
  const { data, isFetching, isError, error } =
    useStudentHighFrequencyWordsAssessmentResultsDrawerQuery(id) || {};

  if (!data || isFetching) {
    if (isError) {
      return (
        <span className="flex items-center justify-center grow text-red-600 min-h-[200px]">
          Error: {error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center grow text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <div
      className="pt-4 lg:pt-10 text-xs sm:text-sm"
      data-testid="results-drawer-assessment-components"
    >
      <div className="border rounded-lg border-gray-300 overflow-hidden mb-8" key={id}>
        <div className="p-3 text-center border-b border-gray-300 font-semibold bg-gray-50">
          {data.name}
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 p-3">
          {data.assessmentAnswers.map(({ answerGiven, content, correct, id, incorrect }, index) => (
            <div className="flex-col p-3 items-center text-gray-900" key={id}>
              <div className="flex items-center space-x-1.5 font-semibold">
                <span>
                  {index + 1}. {content}
                </span>
                {correct ? (
                  <span className="h-5 w-5 rounded-full bg-green-100 text-green-800 flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-full w-full"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                ) : (
                  incorrect && (
                    <span className="h-5 w-5 rounded-full bg-red-100 text-red-800 flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-full w-full"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  )
                )}
              </div>
              {answerGiven && <em>{answerGiven}</em>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
