import React from "react";

export default function Note({ DeleteNote, content, firstName, lastName, date, time }) {
  return (
    <>
      {DeleteNote}
      <p className="mb-2 text-base text-gray-900 whitespace-pre-wrap">{content}</p>
      <div className="text-sm text-gray-400 opacity-40 group-hover:opacity-100">
        by{" "}
        <span className="font-medium text-gray-500" data-heap-redact-text>
          {firstName} {lastName}
        </span>{" "}
        on <span className="font-medium text-gray-500">{date}</span> at{" "}
        <span className="font-medium text-gray-500">{time}</span>
      </div>
    </>
  );
}
