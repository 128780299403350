import React from "react";
import Note from "./Note";

export default function Notes({ CreateNote, DeleteNote, Pagy, isLoading = false, notes = [] }) {
  if (isLoading) {
    return (
      <div className="grow flex flex-col items-center justify-center bg-gray-50 rounded-lg py-10 px-4 mt-4">
        <p className="text-gray-500">Loading...</p>
      </div>
    );
  }

  if (notes.length > 0) {
    return (
      <div className="w-full">
        <div className="ml-1">
          <ul className="divide-y divide-gray-200 px-4 md:px-0" data-testid="notes-list">
            {notes.map(({ id, content, firstName, lastName, date, time }) => (
              <li key={id} className="py-5 md:px-4 lg:px-6 group relative">
                <Note
                  DeleteNote={DeleteNote && <DeleteNote noteId={id} />}
                  id={id}
                  content={content}
                  firstName={firstName}
                  lastName={lastName}
                  date={date}
                  time={time}
                />
              </li>
            ))}
          </ul>
        </div>
        {Pagy}
      </div>
    );
  }

  return (
    <div className="grow flex flex-col items-center justify-center flex-1 bg-gray-50 rounded-lg py-10 px-4">
      <h5 className="font-medium mb-4 text-gray-600">There are no notes yet.</h5>
      {CreateNote}
    </div>
  );
}
