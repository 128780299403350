export const convertDuration = (duration) => {
  function pluralize(str, n) {
    return `${str}${n !== 1 ? "s" : ""}`;
  }
  if (duration < 60) {
    return `${duration} ${pluralize("sec", duration)}`;
  }
  const minutes = Math.floor(duration / 60);
  const remainingSeconds = duration % 60;
  return `${minutes} ${pluralize("min", minutes)}${remainingSeconds ? ` ${remainingSeconds} ${pluralize("sec", remainingSeconds)}` : ""}`;
};

export const duration = (seconds) => {
  return seconds ? ` (up to ${convertDuration(seconds)})` : "";
};
