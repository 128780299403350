import React, { useEffect, useMemo, useState } from "react";
import Content from "./Content";
import TrophyIcon from "../../../../assets/icons/trophy.svg";
import cx from "classnames";
import PropTypes from "prop-types";

export default function Item({
  "data-testid": dataTestId = "xTaskItem",
  correctWordsCount = 0,
  content,
  hasResults = false,
  id,
  isCompleted = false,
  isCurrent = false,
  isDisabled = false,
  isListDisabled = false,
  isLoading = false,
  isReady = false,
  isSelected = false,
  onComplete,
  onSelect,
  task,
  tooltip,
}) {
  const [isExpanded, setIsExpanded] = useState();
  const isCheckDisabled = useMemo(
    () => isCompleted || isLoading || !isCurrent || isDisabled || !isReady || !isSelected,
    [isCompleted, isLoading, isCurrent, isDisabled, isReady, isSelected]
  );

  useEffect(() => {
    setIsExpanded(!!content && isSelected && !isDisabled && !isListDisabled);
  }, [content, isSelected, isDisabled, isListDisabled]);

  return (
    <li data-testid="task-list-item">
      <div
        className={cx("p-3 -mx-3 rounded-lg ", {
          "hover:bg-[rgba(0,0,0,0.04)]": !isSelected && !isDisabled && !isListDisabled,
          "bg-brand-50 border border-brand-600": isSelected && !isDisabled && !isListDisabled,
        })}
      >
        <div className="flex items-start">
          <label
            data-testid={`${dataTestId}-task-${id}-complete`}
            htmlFor={`reading-assessment-task-${id}`}
            className={cx("flex items-center", {
              "cursor-pointer": !isCheckDisabled,
            })}
          >
            <input
              name={`task${id}`}
              data-testid={`task-list-item-input-${id}`}
              checked={isCompleted}
              className="hidden peer"
              disabled={isCheckDisabled}
              id={`reading-assessment-task-${id}`}
              onChange={onComplete}
              type="checkbox"
            />
            <span
              className={cx("w-6 h-6 rounded-full mr-4 flex items-center justify-center", {
                "bg-gray-300 text-gray-300 peer-checked:bg-green-500 peer-checked:text-white":
                  !isCurrent,
                "bg-brand-50 text-brand-50 border-brand-900 border-2": isCurrent && !isReady,
                "bg-white text-white border-brand-900 border-2": isCurrent && isReady,
              })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
              </svg>
            </span>
            <span className="sr-only">Complete</span>
          </label>

          <button
            className={cx(
              "flex justify-between grow-1 mr-4 text-gray-700 text-md font-medium text-left grow",
              {
                "text-zinc-400": isDisabled && !isCompleted,
              }
            )}
            data-testid={`${dataTestId}-task-${id}-select`}
            aria-expanded={isExpanded}
            disabled={isDisabled || isListDisabled}
            onClick={() => {
              onSelect(id);

              if (isSelected && !isListDisabled) {
                setIsExpanded(!isExpanded);
              }
            }}
            type="button"
          >
            {task}

            {content && !isListDisabled && (
              <span
                className={cx({
                  "text-zinc-400": isDisabled && !isCompleted,
                })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className={cx("w-5 h-5 transition-all", {
                    "rotate-180": isExpanded,
                  })}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            )}
          </button>
        </div>
        {hasResults && (
          <div className="my-4 ml-10 flex flex-row flex-start items-center text-zinc-500">
            <TrophyIcon height="20" width="20" />
            <p className="ml-2 text-sm">
              Total Correct:{" "}
              <span data-testid={`${dataTestId}-task-${id}-total-correct`}>
                {correctWordsCount}
              </span>
            </p>
          </div>
        )}
        {isExpanded && (
          <Content
            className={cx("pl-10 pt-4", {
              "text-zinc-400": isDisabled && !isCompleted,
            })}
            content={content}
            data-testid={dataTestId}
            id={id}
            tooltip={tooltip}
          />
        )}
      </div>
    </li>
  );
}

Item.propTypes = {
  "data-testid": PropTypes.string,
  attemptedWordsCount: PropTypes.number,
  content: PropTypes.string,
  hasResults: PropTypes.bool,
  id: PropTypes.number.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isListDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  task: PropTypes.string,
  testID: PropTypes.string,
  tooltip: PropTypes.string,
};
