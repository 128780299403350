import { STATUS } from "./status";

export default function ({
  completed = false,
  failed = false,
  isHfwAssessment = false,
  isScreener = false,
  passed = false,
}) {
  if (isHfwAssessment) {
    if (completed) {
      return STATUS.COMPLETED;
    } else {
      return STATUS.INCOMPLETE;
    }
  }

  if (isScreener) return STATUS.COMPLETED;
  if (completed) return STATUS.COMPLETED;
  if (passed) return STATUS.PASSED;
  if (failed) return STATUS.FAILED;

  return STATUS.UNKNOWN;
}
