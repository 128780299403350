import React from "react";

import Section from "./Section";
import StudentAssessmentItem from "./StudentAssessmentItem";
import ResetHistoryItem from "./ResetHistoryItem";
import { useStudentAssessmentResultsQuery } from "./queries";

const AssessmentResults = ({ assessmentId, studentAssessmentable, studentId, isBaseline }) => {
  const studentAssessmentResultsQuery = useStudentAssessmentResultsQuery(
    assessmentId,
    studentAssessmentable.id,
    isBaseline,
    studentId
  );
  const data = studentAssessmentResultsQuery.data;

  const assessmentPaths = (studentAssessments) => {
    return (
      studentAssessments
        ?.map((assessment) => {
          return assessment.paths;
        })
        .flat() || []
    );
  };

  if (!studentAssessmentResultsQuery.data) {
    if (studentAssessmentResultsQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[100px]">
          Error: {studentAssessmentResultsQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[100px]">
        Loading...
      </span>
    );
  }

  return [
    data?.studentAssessments?.length > 0 ? (
      <Section
        title="Assessment Results"
        showResetMenu={data.showResetMenu}
        differentiator={data.studentAssessments[0]?.id}
        assessmentPaths={assessmentPaths(data.studentAssessments)}
        studentName={data.studentAssessments[0]?.meta.studentName}
        key="student-assessment"
      >
        {data.studentAssessments.map((assessment) => (
          <li className="group" key={assessment.uuid}>
            <StudentAssessmentItem {...assessment} />
          </li>
        ))}
      </Section>
    ) : null,
    data?.studentHFWAssessments?.length > 0 ? (
      <Section
        className="mt-4"
        title="High Frequency Assessment Results"
        showResetMenu={false}
        differentiator={data.studentHFWAssessments[0]?.id}
        assessmentPaths={assessmentPaths(data.studentHFWAssessments)}
        studentName={data.studentName}
        key="hfw-assessment"
      >
        {data.studentHFWAssessments.map((hfwAssessment) => (
          <li className="group" key={hfwAssessment.uuid}>
            <StudentAssessmentItem {...hfwAssessment} />
          </li>
        ))}
      </Section>
    ) : null,
    data?.resetHistory?.length > 0 ? (
      <ul data-testid={`reset-history`} key="reset-history">
        {data.resetHistory.map((version) => (
          <li
            className="group"
            key={version.idx}
            data-testid={`reset-history-version-${version.idx}`}
          >
            <ResetHistoryItem {...version} />
          </li>
        ))}
      </ul>
    ) : null,
  ];
};

export default AssessmentResults;
