import React from "react";
import StudentAssessmentResetPopover from "./StudentAssessmentResetPopover";
import cx from "classnames";

const Section = ({
  className,
  children,
  showResetMenu,
  differentiator,
  assessmentPaths,
  studentName,
  title,
}) => (
  <div className={cx("border border-gray-200 rounded-lg", className)}>
    <h4 className="flex items-center justify-between bg-gray-100 border-b border-gray-200 rounded-t-lg text-gray-600 font-semibold py-2.5 pl-4 pr-2.5 sm:pl-5">
      {title}
      {showResetMenu && (
        <StudentAssessmentResetPopover
          differentiator={differentiator}
          assessmentPaths={assessmentPaths}
          studentName={studentName}
        />
      )}
    </h4>
    <ul className="divide-y divide-gray-200">{children}</ul>
  </div>
);

export default Section;
