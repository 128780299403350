import React, { useMemo } from "react";
import Item from "./Item";
import { parseWordItems, SUBMIT_KEY } from "./utils";
import { readingAssessmentItemType as READING_ASSESSMENT_ITEM_TYPE } from "../../../constants";
import { PUNCTUATION_LOCATIONS } from "./constants";
import cx from "classnames";
import PropTypes from "prop-types";

export default function List({
  "data-testid": dataTestId = "xWordList",
  className,
  disabled = false,
  endItemId,
  isLoading = false,
  isTooltipEnabled = false,
  items = [],
  onUpdateWord,
  studentReadingAssessmentId,
  submitKey = SUBMIT_KEY.CORRECT,
}) {
  const wordItems = useMemo(() => parseWordItems(items, endItemId), [items, endItemId]);

  return (
    <ul data-testid={dataTestId} className={cx("font-normal", className)}>
      {wordItems.map(
        ({ id, incorrectCount, isCallout, isEndWord, isAfterEndWord, isNewLine, text }) => {
          if (isNewLine) {
            <br key={`new-line-${id}`} />;
          } else {
            // NOTE: isIncorrect is calculated here to reduce the need to recacluate
            // all the wordItems above in the memo hook for the single change.
            return (
              <Item
                data-testid={dataTestId}
                disabled={disabled}
                id={id}
                incorrectCount={incorrectCount}
                isCallout={isCallout}
                isEndWord={isEndWord}
                isAfterEndWord={isAfterEndWord}
                isLoading={isLoading}
                isTooltipEnabled={isTooltipEnabled}
                key={`word-${id}`}
                onUpdateWord={onUpdateWord}
                studentReadingAssessmentId={studentReadingAssessmentId}
                submitKey={submitKey}
                text={text}
              />
            );
          }
        }
      )}
    </ul>
  );
}

List.propTypes = {
  "data-testid": PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endItemId: PropTypes.number,
  isLoading: PropTypes.bool,
  isTooltipEnabled: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      assessmentItemType: PropTypes.oneOf([
        READING_ASSESSMENT_ITEM_TYPE.HFW_WORD,
        READING_ASSESSMENT_ITEM_TYPE.NEW_LINE,
        READING_ASSESSMENT_ITEM_TYPE.PUNCTUATION,
        READING_ASSESSMENT_ITEM_TYPE.SKILL_WORD,
        READING_ASSESSMENT_ITEM_TYPE.TITLE,
        READING_ASSESSMENT_ITEM_TYPE.UNKNOWN_WORD,
        READING_ASSESSMENT_ITEM_TYPE.VOCAB_WORD,
        READING_ASSESSMENT_ITEM_TYPE.WORD,
      ]).isRequired,
      definition: PropTypes.string,
      id: PropTypes.number,
      incorrectCount: PropTypes.number,
      punctuationLocation: PropTypes.oneOf([
        PUNCTUATION_LOCATIONS.AFTER,
        PUNCTUATION_LOCATIONS.BEFORE,
      ]),
      readingAssessmentId: PropTypes.number.isRequired,
      sequenceNumber: PropTypes.number.isRequired,
      text: PropTypes.string,
      vocabWordFullText: PropTypes.string,
    })
  ).isRequired,
  onUpdateWord: PropTypes.func,
  studentReadingAssessmentId: PropTypes.number,
  submitKey: PropTypes.oneOf([SUBMIT_KEY.CORRECT, SUBMIT_KEY.END]),
};
