import { useContext } from "react";
import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import { useMutation } from "@tanstack/react-query";
import { patch } from "../../api";

export const useUpdateDurationTimerSecondsRemainingMutation = () => {
  const paths = useContext(RailsPathsContext);

  return useMutation({
    mutationFn: async (secondsRemaining) => {
      const response = await patch(paths.updateTimerSecondsRemaining, {
        timer_seconds_remaining: secondsRemaining,
      });

      return response.data;
    },
  });
};
