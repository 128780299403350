import React from "react";
import { STATUS } from "./status";
import CheckCircleOutline from "assets/icons/check-circle-outline.svg";
import XCircleOutline from "assets/icons/x-circle-outline.svg";
import ExclamationCircleOutline from "assets/icons/exclamation-circle-outline.svg";

export default function AssessmentStatusIcon({ status }) {
  if (status === STATUS.PASSED || status === STATUS.COMPLETED) {
    return <CheckCircleOutline className="size-6 text-green-500 fill-green-100" />;
  } else if (status === STATUS.FAILED) {
    return <XCircleOutline className="size-6 text-red-500 fill-red-100" />;
  } else if (status === STATUS.INCOMPLETE) {
    return <ExclamationCircleOutline className="size-6 text-yellow-500 fill-yellow-100" />;
  }
}
