import React, { createContext, useContext } from "react";
import { useFeatureToggle, TREATMENT } from "../../hooks/useFeatureToggle";

const FeatureToggleContext = createContext(TREATMENT.OFF);

function On({ children }) {
  const value = useContext(FeatureToggleContext);

  return value === TREATMENT.ON ? children : false;
}

function Off({ children }) {
  const value = useContext(FeatureToggleContext);

  return value !== TREATMENT.ON ? children : false;
}

export default function FeatureToggle({ children, featureName }) {
  const treatment = useFeatureToggle({ featureName });

  return (
    <FeatureToggleContext.Provider value={treatment}>{children}</FeatureToggleContext.Provider>
  );
}

FeatureToggle.On = On;
FeatureToggle.Off = Off;
