export function percentage(part, whole) {
  if (whole <= 0) {
    return 0;
  }

  return Math.round((part / whole) * 100);
}

export function copyWords(words) {
  const rows = words.map((word, index) => `${index + 1}. ${word.content}`);
  return rows.join("\n");
}
