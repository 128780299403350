import cx from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import ReteachLessonModal from "./ReteachLessonModal";
import CompleteLessonModal from "./CompleteLessonModal";

const LessonButton = ({ content, isBaseline }) => [
  <ReteachLessonModal
    key="reteach-modal"
    studentProtocolId={content.studentProtocolId}
    lessonId={content.id}
    isBaseline={isBaseline}
    status={content.status}
  />,
  <CompleteLessonModal
    key="complete-modal"
    studentLessonId={content.studentModelId}
    studentProtocolId={content.studentProtocolId}
    lessonId={content.id}
    isBaseline={isBaseline}
    status={content.status}
  >
    <button
      className={twMerge(
        cx("button-secondary rounded-l-none -ml-[1px]", {
          "disabled:bg-green-100 disabled:text-green-800 disabled:ring-green-300 enabled:hover:bg-green-100":
            content.status === "completed",
        })
      )}
      disabled={content.status === "skipped" || content.status === "completed"}
      data-student-lesson-id={content.studentModelId}
      data-student-lesson-status={content.status}
    >
      <div className="w-14">
        {content.status === "skipped" || content.protocolStatus === "tested_out_of"
          ? "Skip"
          : "Complete"}
      </div>
    </button>
  </CompleteLessonModal>,
];

export default LessonButton;
