import React from "react";

import { StudentAssessmentHeader } from "../StudentAssessment";
import { useSparkCookie } from "../common/SparkCookie";
import { VISIBILITY_BANNER_COOKIE_KEY } from "./";

const Header = ({
  durationSeconds,
  assessmentName,
  assessmentIsConditional,
  icon,
  tooltipContent,
  timerSecondsRemaining,
  displayTimer,
}) => {
  const [isDismissed, setIsDismissed] = useSparkCookie(VISIBILITY_BANNER_COOKIE_KEY, "false");
  const onShowVisibilityInfoBanner = () => {
    setIsDismissed("false");
  };

  return (
    <StudentAssessmentHeader
      timerSecondsRemaining={timerSecondsRemaining}
      displayTimer={displayTimer}
      assessmentDuration={durationSeconds}
      assessmentName={assessmentName}
      icon={icon}
      tooltipContent={tooltipContent}
    >
      {assessmentIsConditional && isDismissed === "true" ? (
        <div className="absolute sm:static top-full grow sm:flex justify-end pr-4">
          <button
            className="button-as-link text-xs sm:text-sm"
            onClick={onShowVisibilityInfoBanner}
            type="button"
          >
            <strong>Why am I seeing this?</strong>
          </button>
        </div>
      ) : null}
    </StudentAssessmentHeader>
  );
};

export default Header;
