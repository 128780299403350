import cx from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import CheckIcon from "assets/icons/check-circle-outline.svg";
import { useMarkWordAsTaughtMutation } from "./queries";

const Word = ({ content, sounds, id, index, needsTeaching }) => {
  const markWordAsTaughtMutation = useMarkWordAsTaughtMutation(id);

  return (
    <button
      className={twMerge(
        cx("button-secondary py-0 h-[32px] flex items-center justify-center", {
          "disabled:bg-green-100 disabled:text-green-800": !needsTeaching,
        })
      )}
      disabled={!needsTeaching}
      onClick={markWordAsTaughtMutation.mutate}
      type="button"
    >
      {index}. {content}
      <span
        className={cx({
          "pl-2 pr-[26px]": needsTeaching,
          "taught px-2": !needsTeaching,
        })}
        dangerouslySetInnerHTML={{ __html: sounds }}
      />
      {needsTeaching ? null : <CheckIcon height="14px" width="14px" className="ml-1" />}
    </button>
  );
};

export default Word;
