import { readingAssessmentItemType as READING_ASSESSMENT_ITEM_TYPE } from "../../../constants";
import { PUNCTUATION_LOCATIONS } from "./constants";

//
// Parsing and sorting word items
//

export function parseAfterPunctuation(sequenceNumber, items = []) {
  let text = "";

  const after = items.find((item) => item.sequenceNumber === sequenceNumber + 1);

  if (
    after &&
    isPunctuation(after.assessmentItemType) &&
    after.punctuationLocation === PUNCTUATION_LOCATIONS.AFTER
  ) {
    text = after.text;
  }

  return text;
}

export function parseBeforePunctuation(sequenceNumber, items = []) {
  let text = "";

  const before = items.find((item) => item.sequenceNumber === sequenceNumber - 1);

  if (
    before &&
    isPunctuation(before.assessmentItemType) &&
    before.punctuationLocation === PUNCTUATION_LOCATIONS.BEFORE
  ) {
    text = before.text;
  }

  return text;
}

export function parseWordItems(items = [], endItemId) {
  const words = sortItemSequences(
    items.filter(
      ({ assessmentItemType }) => isWord(assessmentItemType) || isNewLine(assessmentItemType)
    )
  );

  let endItemSequenceNumber = items.find((item) => item.id === endItemId)?.sequenceNumber;

  return words.reduce((arr, { assessmentItemType, id, incorrectCount, sequenceNumber, text }) => {
    if (isNewLine(assessmentItemType)) {
      arr.push({
        isNewLine: true,
      });
    } else {
      const before = parseBeforePunctuation(sequenceNumber, items);
      const after = parseAfterPunctuation(sequenceNumber, items);

      arr.push({
        id,
        incorrectCount,
        isCallout: isCallout(assessmentItemType),
        isEndWord: isEndWord(id, endItemId),
        isAfterEndWord: isAfterEndWord(sequenceNumber, endItemSequenceNumber),
        text: before + text + after,
      });
    }

    return arr;
  }, []);
}

export function sortItemSequences(items = []) {
  return items.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
}

//
// Determining word item minutia
//

export function isEndWord(id, endItemId) {
  return id === endItemId;
}

export function isAfterEndWord(sequenceNumber, endItemSequenceNumber) {
  if (!endItemSequenceNumber) {
    return false;
  }

  return sequenceNumber > endItemSequenceNumber;
}

export function isCallout(assessmentItemType) {
  return [
    READING_ASSESSMENT_ITEM_TYPE.HFW_WORD,
    READING_ASSESSMENT_ITEM_TYPE.UNKNOWN_WORD,
    READING_ASSESSMENT_ITEM_TYPE.VOCAB_WORD,
  ].includes(assessmentItemType);
}

//
// Testing word item types
//

export function isNewLine(assessmentItemType) {
  return assessmentItemType === READING_ASSESSMENT_ITEM_TYPE.NEW_LINE;
}

export function isPunctuation(assessmentItemType) {
  return assessmentItemType === READING_ASSESSMENT_ITEM_TYPE.PUNCTUATION;
}

export function isWord(assessmentItemType) {
  return [
    READING_ASSESSMENT_ITEM_TYPE.HFW_WORD,
    READING_ASSESSMENT_ITEM_TYPE.SKILL_WORD,
    READING_ASSESSMENT_ITEM_TYPE.UNKNOWN_WORD,
    READING_ASSESSMENT_ITEM_TYPE.VOCAB_WORD,
    READING_ASSESSMENT_ITEM_TYPE.WORD,
  ].includes(assessmentItemType);
}

export const SUBMIT_KEY = {
  CORRECT: "correctKey",
  END: "endKey",
};
