import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get } from "../../api";

export const useLessonPlanQuery = (studentLessonId) => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["lesson-plan", studentId, studentLessonId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/lesson_plan`, {
        signal,
      });

      return response.data;
    },
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};
