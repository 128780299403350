import { Button } from "@Ignite-Reading/ui-kit/components";
import { twcx } from "@Ignite-Reading/ui-kit/utils";
import React from "react";

const JoinSessionButton = ({ className, clientURL }) => {
  const onClick = () => {
    window.open(clientURL, "_blank");
  };

  return (
    <Button
      onClick={onClick}
      className={twcx("button-primary whitespace-nowrap", className)}
      disabled={!clientURL}
      data-testid="join-nsb-button"
    >
      Join Session
    </Button>
  );
};

export default JoinSessionButton;
