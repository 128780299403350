import React from "react";
import Tooltip from "./Tooltip";
import Markdown from "react-markdown";
import cx from "classnames";
import PropTypes from "prop-types";

export default function Content({
  "data-testid": dataTestId = "xContent",
  className,
  content,
  tooltip,
}) {
  return (
    <Markdown
      className={cx("relative z-1 pl-10 pt-4 text-gray-500", className)}
      components={{
        h1({ children }) {
          return <h1 className="font-bold mb-4">{children}</h1>;
        },
        p({ children }) {
          return <p className="mb-4">{children}</p>;
        },
        ol({ children }) {
          return <ol className="my-4 pl-8 list-decimal">{children}</ol>;
        },
        ul({ children }) {
          return <ul className="my-4 pl-8 list-disc">{children}</ul>;
        },
        li({ children }) {
          return <li className="mb-4">{children}</li>;
        },
        a({ href, children }) {
          if (tooltip) {
            return (
              <Tooltip data-testid={dataTestId} text={children} href={href} content={tooltip} />
            );
          } else {
            return (
              <a className="font-bold underline text-brand-600" href={href}>
                {children}
              </a>
            );
          }
        },
      }}
    >
      {content}
    </Markdown>
  );
}

Content.propTypes = {
  "data-testid": PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};
