import React from "react";
import cx from "classnames";

export default ({ className, children, title = "Challenge results" }) => (
  <div className={cx("space-y-2 mb-8", className)}>
    {title && <h5 className="uppercase font-medium text-zinc-500 text-sm">{title}</h5>}
    <div className="space-y-12">
      <ul className="space-y-2.5">{children}</ul>
    </div>
  </div>
);
