import React, { useState, useCallback, useEffect, useMemo } from "react";
import TaskList from "./TaskList";
import WordList from "./WordList";
import Results from "./Results";
import Sparky from "../../../assets/images/sparky.svg";
import ClappingHands from "../../../assets/images/clapping-hands.svg";
import StopIcon from "../../../assets/icons/stop.svg";
import StopSignIcon from "../../../assets/icons/stop-sign.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right-outline.svg";
import ArrowLeftIcon from "../../../assets/icons/arrow-left-outline.svg";
import { Button } from "@Ignite-Reading/ui-kit";
import {
  useUpdateAssessmentMutation,
  useUpdateTaskMutation,
  useUpdateWordMutation,
} from "./queries";
import { getSelectedTask, getNextTask, sortTaskSequences } from "./TaskList/utils";
import { SUBMIT_KEY } from "./WordList/utils";
import { useStudentAssessmentQuery, useStudentAssessmentContext } from "../StudentAssessment";
import { readingAssessmentStatusType as READING_ASSESSMENT_STATUS_TYPE } from "../../constants";
import cx from "classnames";

export default function () {
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [isTooltipEnabled, setIsTooltipEnabled] = useState(false);

  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { setOpen } = useStudentAssessmentContext();
  // handlers that use the mutates are created below for further convenience
  const { mutate: mutateAssessment } = useUpdateAssessmentMutation();
  const { isLoading: isLoadingTasks, mutate: mutateTask } = useUpdateTaskMutation();
  const { isLoading: isLoadingWords, mutate: mutateWord } = useUpdateWordMutation();

  const {
    readingAssessmentTaskTooltips = [],
    readingAssessmentTasks = [],
    status,
  } = useMemo(() => studentAssessmentQuery.data || {}, [studentAssessmentQuery]);

  // the current task to be worked on
  const currentTask = useMemo(() => {
    return sortTaskSequences(readingAssessmentTasks.filter(({ completed }) => !completed))[0] || {};
  }, [readingAssessmentTasks]);

  // the currently selected task, that can only be a task that is
  // current or already completed
  const selectedTask = useMemo(() => {
    return getSelectedTask(selectedTaskId, readingAssessmentTasks);
  }, [selectedTaskId, readingAssessmentTasks]);

  // The last task in the sequence
  const finalTask = useMemo(() => {
    return [...sortTaskSequences(readingAssessmentTasks)].pop() || {};
  }, [readingAssessmentTasks]);

  // The task used for results can either be the selected task,
  // unless the final task is completed
  const resultsTask = useMemo(() => {
    // Tasks without readingAssessmentItems also have completed and
    // results information, so we need to check that they do have word
    // lists. If they don't, it's expected that the value is null and
    // not an empty array. Checking for word lists and returning an
    // empty object as a default simplifies things.
    if (finalTask.completed && finalTask.readingAssessmentItems) {
      return finalTask;
    } else if (selectedTask.completed && selectedTask.readingAssessmentItems) {
      return selectedTask;
    } else {
      return {};
    }
  }, [finalTask, selectedTask]);

  const isCompleted = useMemo(() => {
    return status === READING_ASSESSMENT_STATUS_TYPE.COMPLETED;
  }, [status]);

  const isNextAvailable = useMemo(() => {
    if (isCompleted) {
      return false;
    } else if (finalTask.completed) {
      return false;
    } else if (selectedTask.completed) {
      return false;
    } else if (currentTask.readingAssessmentItems) {
      if (currentTask.endItemId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [currentTask, finalTask, selectedTask, isCompleted]);

  const handleSelectTask = useCallback(
    (id) => {
      const selectedTask = getSelectedTask(id, readingAssessmentTasks);

      setSelectedTaskId(selectedTask.id);
    },
    [readingAssessmentTasks]
  );

  const handleNextTask = useCallback(() => {
    const nextTask = getNextTask(selectedTask.sequenceNumber, readingAssessmentTasks);

    setSelectedTaskId(nextTask.id);
  }, [selectedTask.sequenceNumber, readingAssessmentTasks]);

  const handleCompleteAssessment = () => {
    mutateAssessment(
      {},
      {
        onSuccess: () => setOpen(false),
      }
    );
  };

  const handleCompleteTask = () => {
    mutateTask(
      {
        completed: true,
        id: currentTask.id,
        studentReadingAssessmentId: currentTask.studentReadingAssessmentId,
      },
      {
        onSuccess: handleNextTask,
      }
    );
  };

  const handleUpdateWord = (options) => {
    mutateWord(options, {
      onSuccess: (data) => {
        // get the last task from the data here, as the final task might not yet
        // be updated by the time the task needs to be completed below
        const lastTask = data.readingAssessmentTasks.at(-1);

        if (finalTask.id === currentTask.id && lastTask.endItemId && !finalTask.completed) {
          handleCompleteTask();
        }
      },
    });
  };

  useEffect(() => {
    // Check if the currentTask has an id before setting the selected task
    // or else the setter gets called too many times because the current task
    // is still empty
    if (!selectedTaskId && currentTask.id) {
      handleSelectTask(currentTask.id);
    }
  }, [currentTask.id, selectedTaskId, handleSelectTask]);

  useEffect(() => {
    setIsTooltipEnabled(!!selectedTask.endItemId);
  }, [selectedTask.endItemId]);

  if (studentAssessmentQuery.isLoading) {
    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  } else if (studentAssessmentQuery.isError) {
    return (
      <span
        data-testid="shared-reading-error"
        className="flex items-center justify-center text-red-600 min-h-[200px]"
      >
        Error: {studentAssessmentQuery.error.message}
      </span>
    );
  } else if (readingAssessmentTasks?.length < 1) {
    return false;
  } else {
    return (
      <div data-testid="shared-reading-card" className="shared-reading-card-layout mt-6">
        <aside
          data-testid="shared-reading-card-aside"
          className={cx("shared-reading-card-aside", {
            "p-4 border rounded-lg border-green-500 bg-green-50":
              finalTask.completed && !isCompleted,
            completed: isCompleted || (selectedTask.completed && selectedTask.id !== finalTask.id),
          })}
        >
          {resultsTask.completed && (
            <header className="shared-reading-aside-header ">
              {!isCompleted && finalTask.completed && (
                <h3 className="text-zinc-500 text-sm uppercase mb-4">{finalTask.task} Results</h3>
              )}
              <div className="flex flex-row items-center justify-between">
                <Results
                  correct={resultsTask.correctWordsCount}
                  attempted={resultsTask.attemptedWordsCount}
                  errored={resultsTask.attemptedWordsCount - resultsTask.correctWordsCount}
                />
                {!isCompleted && finalTask.completed && (
                  <span className="py-8 pl-4">
                    <ClappingHands height="100" width="100" />
                  </span>
                )}
              </div>
            </header>
          )}
          <TaskList
            className="shared-reading-task-list"
            data-testid="shared-reading-task-list"
            isLoading={isLoadingTasks}
            onComplete={handleCompleteTask}
            onSelect={handleSelectTask}
            selectedTaskId={selectedTask.id}
            tasks={readingAssessmentTasks}
            tooltips={readingAssessmentTaskTooltips}
          />

          {finalTask.completed && !isCompleted && (
            <Button
              onClick={handleCompleteAssessment}
              className="mt-6 button-primary w-full flex flex-row items-center justify-center"
              disabled={isLoadingTasks || isLoadingWords}
              data-testid="next-shared-reading-task"
            >
              Continue Lesson
              <ArrowRightIcon className="ml-1" width="16" height="16" />
            </Button>
          )}
        </aside>

        <section className="shared-reading-card-work-area rounded-lg">
          {finalTask.completed ? (
            <>
              <header className="text-gray-600 mb-4">
                <h3 className="font-bold text-md text-center">{finalTask.task}</h3>
              </header>

              <WordList
                data-testid="shared-reading-word-list"
                disabled={isCompleted}
                endItemId={finalTask.endItemId}
                isTooltipEnabled={!isCompleted}
                items={finalTask.readingAssessmentItems}
                onUpdateWord={handleUpdateWord}
                studentReadingAssessmentId={finalTask.studentReadingAssessmentId}
                submitKey={SUBMIT_KEY.END}
              />
            </>
          ) : (
            <>
              {selectedTask.readingAssessmentItems ? (
                <div
                  className={cx("px-6 py-8 rounded-lg", {
                    "bg-amber-50 border-amber-500 border":
                      isTooltipEnabled && currentTask.id === selectedTask.id,
                    "bg-brand-50 border-brand-500 border":
                      !isTooltipEnabled && currentTask.id === selectedTask.id,
                  })}
                >
                  <header className="text-gray-600 mb-4">
                    {isTooltipEnabled && !selectedTask.completed && (
                      <>
                        <h2 className="mb-4 text-zinc-700 text-2xl font-bold flex flex-row items-center">
                          <span className="text-amber-500 mr-2">
                            <StopSignIcon height="32" width="32" />
                          </span>
                          Add Stop
                        </h2>
                        <div className="mb-4 flex flex-row items-center justify-between">
                          <p className="mr-4">
                            Complete tracking by clicking on the last word the student attempted.
                          </p>
                          <div className="relative">
                            <StopIcon width="96" height="86" className="text-white relative z-1" />
                            <span className="text-zinc-700 font-bold text-md absolute top-[24px] left-[14px]">
                              Word
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <h3 className="font-bold text-md text-center">{selectedTask.task}</h3>
                  </header>

                  <WordList
                    data-testid="shared-reading-word-list"
                    endItemId={selectedTask.endItemId}
                    isLoading={isLoadingWords}
                    isTooltipEnabled={isTooltipEnabled}
                    items={selectedTask.readingAssessmentItems}
                    onUpdateWord={handleUpdateWord}
                    studentReadingAssessmentId={selectedTask.studentReadingAssessmentId}
                    submitKey={
                      isTooltipEnabled || selectedTask.endItemId
                        ? SUBMIT_KEY.END
                        : SUBMIT_KEY.CORRECT
                    }
                  />

                  {!selectedTask.endItemId && (
                    <>
                      {isTooltipEnabled ? (
                        <button
                          className="mt-4 p-2 text-zinc-700 flex flex-row items-center justify-center"
                          onClick={() => setIsTooltipEnabled(false)}
                          type="button"
                        >
                          <ArrowLeftIcon className="mr-1" width="16" height="16" />
                          Back
                        </button>
                      ) : (
                        <button
                          onClick={() => setIsTooltipEnabled(true)}
                          className="mt-4 bg-fuchsia-500 p-2 rounded-lg text-white w-full flex flex-row items-center justify-center"
                          disabled={isLoadingTasks || isLoadingWords}
                          data-testid="next-shared-reading-task"
                        >
                          Add Stop
                          <ArrowRightIcon className="ml-1" width="16" height="16" />
                        </button>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="px-12 py-14 flex flex-col items-center m-auto max-w-[420px]">
                  <div className="text-zinc-700 mb-6">
                    <h3 className="font-bold text-sm mb-4">
                      ✨ <em>Growth Mindset Boost Reminder</em> ✨
                    </h3>

                    <p className="mb-4">
                      <em>
                        Time to hype your student up! The passage might look a little daunting at
                        first, but remind them—they’ve already learned these skills! They’ve done it
                        before, and they can totally do it again.
                      </em>
                    </p>
                    <p>
                      <em>Let them know they&apos;ve got this!</em>
                    </p>
                  </div>
                  <Sparky width="109" height="86" />
                </div>
              )}
            </>
          )}
        </section>

        {isNextAvailable && (
          <footer className="shared-reading-card-footer">
            <Button
              onClick={handleCompleteTask}
              className="button-primary w-full flex flex-row items-center justify-center"
              disabled={isLoadingTasks || isLoadingWords}
              data-testid="next-shared-reading-task"
            >
              Next
              <ArrowRightIcon className="ml-1" width="16" height="16" />
            </Button>
          </footer>
        )}
      </div>
    );
  }
}
