import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSetStudentAssessmentQueryOnSuccess } from "../StudentAssessment/queries";
import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import { patch, put } from "../../api";
import { insertIdsIntoPath } from "../../utils";

export const useUpdateAssessmentMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async () => {
      const { data } = await put(paths.updateAssessment);

      return data;
    },
    onSuccess,
  });
};

export const useUpdateTaskMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async ({ completed, id, studentReadingAssessmentId }) => {
      const path = insertIdsIntoPath({ id, studentReadingAssessmentId }, paths.updateTask);

      const { data } = await patch(path, {
        completed,
      });

      return data;
    },
    onSuccess,
  });
};

export const useUpdateWordMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async ({ correct, id, studentReadingAssessmentId, ended }) => {
      const path = insertIdsIntoPath({ id, studentReadingAssessmentId }, paths.updateWord);

      const { data } = await patch(path, {
        correct,
        ended,
      });

      return data;
    },
    onSuccess,
  });
};
