export default function (ids = {}, path) {
  if (!path) return null;

  for (let key in ids) {
    const regex = new RegExp(`:${key}`, "g");

    path = path.replace(regex, ids[key]);
  }

  return path;
}
