import { useCallback, useContext } from "react";

import { SubBoardSelectionsContext } from "../contexts/TutorCalendar";
import { isPast, isSameDay } from "date-fns";
import { tutorStudentStatus } from "../constants";
import { useTimeBlocksQuery } from "../components/TutorCalendar/queries";

const { ACCEPTED } = tutorStudentStatus;

export const useSubBoardSelector = () => {
  const { selectedDates, setSelectedDates, selectedTimeBlocks, setSelectedTimeBlocks } =
    useContext(SubBoardSelectionsContext);
  const timeBlocksQuery = useTimeBlocksQuery();
  const { calendarEvents: timeBlocks } = timeBlocksQuery.data || {
    calendarEvents: [],
  };
  const dateIsSelected = useCallback(
    (date) => {
      return typeof date === "string"
        ? selectedDates.has(date)
        : selectedDates.has(date.toString());
    },
    [selectedDates]
  );
  const timeBlockIsSelected = useCallback(
    (timeBlock) => {
      return typeof timeBlock === "string"
        ? selectedTimeBlocks.has(timeBlock)
        : selectedTimeBlocks.has(timeBlock.toString());
    },
    [selectedTimeBlocks]
  );
  const acceptedTimeBlocksForDate = useCallback(
    (date) => {
      return timeBlocks
        .filter((block) => block.status === ACCEPTED && isSameDay(date, block.startTime))
        .map((b) => b.startTime.toString());
    },
    [timeBlocks]
  );
  const deselectAll = () => {
    setSelectedDates(new Set([]));
    setSelectedTimeBlocks(new Set([]));
  };
  const selectDate = (date, overrideIsPast = false, successCb = () => {}) => {
    if (isPast(date) && !overrideIsPast) {
      return;
    }
    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (!dateIsSelected(dateWithoutTime) && acceptedTimeBlocksForDate(dateWithoutTime).length > 0) {
      setSelectedDates((prev) => {
        const next = new Set(prev);
        return next.add(dateWithoutTime.toString());
      });
      acceptedTimeBlocksForDate(dateWithoutTime).forEach((timeBlock) => {
        selectTimeBlock(timeBlock);
      });
      successCb();
    }
  };
  const deselectDate = (date, successCb = () => {}) => {
    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (dateIsSelected(dateWithoutTime)) {
      setSelectedDates((prev) => {
        const next = new Set(prev);
        next.delete(dateWithoutTime.toString());
        return next;
      });
      successCb();
    }
  };
  const selectTimeBlock = (timeBlock, successCb = () => {}) => {
    if (isPast(new Date(timeBlock))) {
      return;
    }
    if (!timeBlockIsSelected(timeBlock)) {
      setSelectedTimeBlocks((prev) => {
        const next = new Set(prev);
        return next.add(timeBlock.toString());
      });
      successCb();
    }
  };
  const deselectTimeBlock = (timeBlock, successCb = () => {}) => {
    if (timeBlockIsSelected(timeBlock)) {
      setSelectedTimeBlocks((prev) => {
        const next = new Set(prev);
        next.delete(timeBlock.toString());
        return next;
      });
      successCb();
    }
  };
  const deleteSelectedTimeBlocksForDate = (date) => {
    setSelectedTimeBlocks((prev) => {
      const next = new Set(prev);
      next.forEach((tb) => {
        if (isSameDay(Date.parse(date), Date.parse(tb))) {
          next.delete(tb);
        }
      });
      return next;
    });
  };
  return {
    dateIsSelected,
    timeBlockIsSelected,
    acceptedTimeBlocksForDate,
    deselectAll,
    selectDate,
    deselectDate,
    selectTimeBlock,
    deselectTimeBlock,
    deleteSelectedTimeBlocksForDate,
  };
};
