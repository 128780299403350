import React, { useMemo } from "react";
import { PagyProvider, PagyNavigation } from "../common/Pagy";
import Notes from "./Notes";
import DeleteNote from "./DeleteNote";
import CreateNoteModal from "./CreateNoteModal";
import { useNotesQuery } from "./queries";
import Navigation from "../Student/Navigation";
import PlacedOutsideBanner from "../common/PlacedOutsideBanner";

export default ({ allowDeletion }) => {
  const notesQuery = useNotesQuery();

  const hasPages = useMemo(() => {
    return notesQuery.data?.pagy?.pages > 1;
  }, [notesQuery]);

  return [
    <Navigation key="navigation" />,
    <PlacedOutsideBanner key="place-out" />,
    <PagyProvider key="content">
      <Notes
        DeleteNote={allowDeletion && DeleteNote}
        CreateNote={
          notesQuery.data?.studentId && <CreateNoteModal studentId={notesQuery.data.studentId} />
        }
        Pagy={hasPages && <PagyNavigation query={notesQuery} />}
        notes={notesQuery.data?.notes}
        isLoading={notesQuery.isLoading}
      />
    </PagyProvider>,
  ];
};
