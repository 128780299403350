import React from "react";

import { Table } from "../common/Table";
import MissingAvailabilityPrompt from "./MissingAvailabilityPrompt";
import { useStudentsQuery } from "./queries";
import TutorStudentListItem from "./TutorStudentListItem";

const TutorStudentList = ({
  all,
  missingAvailabilityName,
  shouldShowMissingAvailabilityPrompt,
}) => {
  const studentsQuery = useStudentsQuery(all);

  return [
    shouldShowMissingAvailabilityPrompt ? (
      <MissingAvailabilityPrompt
        missingAvailabilityName={missingAvailabilityName}
        key="missing-availability-prompt"
      />
    ) : null,
    <div key="tutor-student-list" className="mt-8 flex flex-col">
      <Table
        columns={[
          {
            className: "xl:w-1/6 xl:min-w-[160px]",
            id: "block",
            isSortable: true,
            label: "Tutoring Block",
          },
          {
            id: "student",
            isSortable: true,
            label: "Student",
          },
          {
            id: "protocol",
            isSortable: true,
            label: "Protocol",
          },
          {
            className: "text-center",
            id: "school",
            isSortable: true,
            label: "School",
          },
          {
            id: "grade",
            isSortable: true,
            label: "Grade Level",
          },
          {
            colSpan: 2,
            id: "assignment",
            isSortable: true,
            label: "Assignment",
          },
        ]}
        data-testid="tutor-student-list"
        defaultSortKey="block"
        query={studentsQuery}
      >
        {(data) =>
          data.students.length > 0 ? (
            data.students.map((student) => <TutorStudentListItem key={student.uuid} {...student} />)
          ) : (
            <tr>
              <td className="bg-white p-4 text-sm text-gray-900 sm:pl-6 rounded-b-lg" colSpan={5}>
                <p className="flex items-center justify-center grow text-gray-500 min-h-[120px]">
                  You don&lsquo;t have any students assigned.
                </p>
              </td>
            </tr>
          )
        }
      </Table>
    </div>,
  ];
};

export default TutorStudentList;
