import kebabCase from "lodash/kebabCase";
import React from "react";

import { StudentAssessment, StudentAssessmentHeader } from "../StudentAssessment";
import ContentCollapsed from "./ContentCollapsed";
import ContentExpanded from "./ContentExpanded";
import { studentOneMinuteChallengeType } from "../../constants";

const {
  InitialSoundSorting,
  SayItAndMoveIt,
  OralBlending,
  OralSegmenting,
  TeachingLetterPatterns,
  LetterPatternMatching,
} = studentOneMinuteChallengeType;
const typeToNameMap = {
  [InitialSoundSorting]: "Initial Sound Sorting",
  [SayItAndMoveIt]: "Say It And Move It",
  [OralBlending]: "Oral Blending",
  [OralSegmenting]: "Oral Segmenting",
  [TeachingLetterPatterns]: "Letter Patterns",
  [LetterPatternMatching]: "Pattern Matching",
};
const typeToTooltipContentMap = {
  [InitialSoundSorting]: [
    <p className="mb-6" key="paragraph-1">
      This activity comes after introducing pictures and their initial sounds. Stay on the page with{" "}
      <strong>pictures only</strong>, no letters.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “I will say a word. Listen for the first sound. Tell me what (picture) word has the same first
      sound.” <em>Signal picture choices while giving these instructions.</em>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student cannot match the words with the same initial sound on the 1st try, mark it and
      follow the error correction process.
    </p>,
  ],
  [SayItAndMoveIt]: [
    <p className="mb-6" key="paragraph-1">
      Show the slide with Elkonin boxes and mark each phoneme in the boxes on GSB.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “Let’s work with sounds in words! I will say a word. Then I will say it and move it! After me,
      it’s your turn! My turn: (word). <strong>/x/ /x/ /x/,.</strong> Mark each phoneme in the
      Elkonin boxes as you say it. Your turn!”
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student cannot copy the model correctly on the 1st try, mark it and follow error
      correction process.
    </p>,
  ],
  [OralBlending]: [
    <p className="mb-6" key="paragraph-1">
      Share the slide with the listening image.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “I will tap the sounds in a word. You tell me what word the sounds make! Watch my fingers so
      we can stay together.“
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student can not blend the sounds together on the 1st, try, click on the word and follow
      the error correction process.
    </p>,
  ],
  [OralSegmenting]: [
    <p className="mb-6" key="paragraph-1">
      Stay on the slide with the listening image.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “Now let’s switch! I will say a word, and you tap the sounds!“
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student can not segment the sounds on the 1st, try, click on the word and follow the
      error correction process.
    </p>,
  ],
  [TeachingLetterPatterns]: [
    <p className="mb-6" key="paragraph-1">
      Share the slide with the pictures and patterns. This activity is for the Guided Practice
      section.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “I’ll say a word. Listen carefully and think about the sound pattern at the end of my word.
      Then, tell me the word (picture) that matches!”{" "}
      <em>Signal the picture/word choices for matching.</em>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student cannot match the patterns on the 1st try, mark it and follow the error
      correction process.
    </p>,
  ],
  [LetterPatternMatching]: [
    <p className="mb-6" key="paragraph-1">
      Share the slide with the images and clue words <strong>only</strong>.<br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “We are going to listen for vowel sounds! First, we’ll review short vowel sounds like /x/ in
      ___ and /x/ in ___. Then, we’ll listen for long vowel sounds like /X/ in ___ and /X/ in ____.
      Listen to each word I say. You’ll determine the vowel sound, then say the word with the
      matching sound.“
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student cannot match the vowel sounds on the 1st try, mark it and follow the error
      correction process.
    </p>,
  ],
};

const StudentPhonologicalAwarenessAssessment = (props) => (
  <StudentAssessment
    {...props}
    data-testid={`${kebabCase(props.type)}-lesson-card`}
    queryKey={["student-pa-drill", props.assessmentId]}
    resetModalContent="All phonological awareness data you marked will be erased and reset for this lesson. Are you sure you want to reset?"
    resetModalTitle="Reset Phonological Awareness"
  >
    <StudentAssessmentHeader
      timerSecondsRemaining={props.timerSecondsRemaining}
      displayTimer={props.displayTimer}
      assessmentDuration={props.durationSeconds}
      assessmentName={typeToNameMap[props.type]}
      assessmentSubtitle="Phonological Awareness"
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 41" fill="none">
          <path
            stroke="currentColor"
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.125 14.875C3.76786 10.4725 6.875 3 15.9643 3C23.0357 3 26.25 9.60375 26.25 12.2453M17.8929 29.4151C17.4643 32.4968 15.4785 38 10.8214 38C6.96429 38 5.46429 34.2579 5.67857 32.717M10 29.4151C12.5714 28.9748 14.5499 26.2318 10.8214 21.125C7.09291 16.0182 8.75 11.75 11.4643 9.51414C14.8888 6.69323 21.1071 7.62261 21.1071 12.2453"
          />
          <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.955 18.23C37.5 19.2996 37.5 20.6997 37.5 23.5V24.875V30.0833V31.2322C37.5 32.3459 36.1536 32.9036 35.3661 32.1161L33.6994 30.4494C33.465 30.215 33.1471 30.0833 32.8156 30.0833L26.0417 30.0833C23.9074 30.0833 22.8402 30.0833 21.9887 29.7629C20.6412 29.2557 19.5777 28.1922 19.0705 26.8446C18.75 25.9931 18.75 24.926 18.75 22.7917C18.75 20.6574 18.75 19.5902 19.0705 18.7387C19.5777 17.3912 20.6412 16.3277 21.9887 15.8205C22.8402 15.5 23.9074 15.5 26.0417 15.5H29.5C32.3003 15.5 33.7004 15.5 34.77 16.045C35.7108 16.5243 36.4757 17.2892 36.955 18.23ZM33.5654 17.8633L33.364 24.0339H31.8992L31.6917 17.8633H33.5654ZM31.6428 25.9443C31.6428 25.6839 31.7323 25.4683 31.9114 25.2974C32.0945 25.1224 32.3346 25.0349 32.6316 25.0349C32.9327 25.0349 33.1728 25.1224 33.3518 25.2974C33.5308 25.4683 33.6204 25.6839 33.6204 25.9443C33.6204 26.1966 33.5308 26.4102 33.3518 26.5852C33.1728 26.7602 32.9327 26.8477 32.6316 26.8477C32.3346 26.8477 32.0945 26.7602 31.9114 26.5852C31.7323 26.4102 31.6428 26.1966 31.6428 25.9443ZM27.0835 17.8633H27.5474L30.8677 26.75H28.9146L28.2982 24.8762H25.1043L24.4895 26.75H22.5425L25.8445 17.8633H26.2961H27.0835ZM25.5748 23.4419L26.6992 20.0148L27.8265 23.4419H25.5748Z"
          />
        </svg>
      }
      tooltipContent={
        <>
          <h5 className="font-semibold mb-1.5" key="title-1">
            {typeToNameMap[props.type]} Overview
          </h5>
          {typeToTooltipContentMap[props.type]}
        </>
      }
    />
    <ContentExpanded />
    <ContentCollapsed />
  </StudentAssessment>
);

export default StudentPhonologicalAwarenessAssessment;
