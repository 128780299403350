import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export default ({ children }) => {
  const ref = useRef();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { intersectionRatio, isIntersecting } = entries[0];

        setIsSticky(intersectionRatio >= 0 && intersectionRatio < 1 && !isIntersecting);
      },
      { threshold: [1] }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return [
    <div className="-top-[1px]" ref={ref} key="detector" />,
    isSticky && (
      <div
        className={twMerge(
          cx(
            "sticky top-[var(--timer-sticky-top)] md:top-[var(--md-timer-sticky-top)] lg:top-[var(--lg-timer-sticky-top)] w-full z-10"
          )
        )}
        key="content"
      >
        {children}
      </div>
    ),
  ];
};
