import React, { useRef } from "react";
import ConfirmationModalForMutation from "../common/./ConfirmationModalForMutation";
import { useSubmitLessonMutation } from "./queries";
import CheckCircleOutline from "assets/icons/check-circle-outline.svg";
import { toast } from "react-toastify";

const CompleteLessonModal = ({
  children,
  isBaseline,
  lessonId,
  onComplete,
  studentLessonId,
  studentProtocolId,
}) => {
  const toastIdRef = useRef();
  const submitLessonMutation = useSubmitLessonMutation({
    studentLessonId,
    studentProtocolId,
    lessonId,
    isBaseline,
  });

  const handleComplete = () => {
    if (onComplete) onComplete();

    toastIdRef.current = toast.loading("Saving. Please wait...");
    toast.update(toastIdRef.current, {
      autoClose: 1800,
      isLoading: false,
      render: "Lesson completed",
      type: "success",
    });
  };

  return (
    <ConfirmationModalForMutation
      icon={
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <CheckCircleOutline className="h-6 w-6 text-green-600" strokeWidth={2} />
        </div>
      }
      message={[
        <p className="text-sm text-gray-500 font-normal" key="p-1">
          Double-check if every part has been taught. Once marked complete, it cannot be changed!
        </p>,
        <p className="text-sm text-zinc-500 font-normal" key="p-2">
          <em>Clicking &ldquo;Complete Lesson&rdquo; will take you to the next lesson.</em>
        </p>,
        <p className="text-sm text-zinc-500 font-normal" key="p-2">
          <strong>Are you sure you want to mark this lesson as complete?</strong>
        </p>,
      ]}
      onConfirm={handleComplete}
      mutation={submitLessonMutation}
      mutateParams="completed"
      submitButtonLabel="Complete Lesson"
      title="Lesson Completion Check"
    >
      {children}
    </ConfirmationModalForMutation>
  );
};

export default CompleteLessonModal;
