export function hasResults(readingAssessmentItems = [], completed) {
  return readingAssessmentItems?.length > 0 && completed;
}

export function isCurrent(sequenceNumber, tasks = []) {
  return (
    sortTaskSequences(tasks.filter(({ completed }) => !completed))[0]?.sequenceNumber ===
    sequenceNumber
  );
}

export function isDisabled(sequenceNumber, tasks = []) {
  let incomplete = sortTaskSequences(tasks.filter(({ completed }) => !completed));

  incomplete = incomplete.map(({ sequenceNumber }) => sequenceNumber);
  incomplete.shift();

  return incomplete.includes(sequenceNumber);
}

export function isReady(endItemId, itemLength) {
  if (!itemLength || itemLength === 0) return true;

  return !!endItemId;
}

export function parseTaskItems(tasks = [], tooltips = []) {
  return sortTaskSequences(
    tasks.reduce((arr, task) => {
      arr.push({
        ...task,
        isReady: isReady(task.endItemId, task.readingAssessmentItems?.length),
        isCurrent: isCurrent(task.sequenceNumber, tasks),
        isDisabled: isDisabled(task.sequenceNumber, tasks),
        hasResults: hasResults(task.readingAssessmentItems, task.completed),
        tooltip: parseTooltipContent(task.content, tooltips),
      });

      return arr;
    }, [])
  );
}

// NOTE: This is built to find one bookmark in a markdown string
export function parseTooltipContent(content, tooltips = []) {
  let bookmark = content?.match(/\[.+?\]\(#.+?\)/g);

  if (!bookmark) return null;

  bookmark = content.match(/\(#.+?\)/g)[0].replace(/[()#]/g, "");

  return tooltips.find(({ name }) => name === bookmark)?.content || null;
}

export function sortTaskSequences(tasks = []) {
  return tasks.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
}

export function getSelectedTask(taskId, tasks) {
  return tasks.find(({ id }) => taskId === id) || {};
}

export function getNextTask(sequenceNumber, tasks) {
  const nextSequence = parseInt(sequenceNumber) + 1;

  return tasks.find(({ sequenceNumber }) => sequenceNumber === nextSequence) || {};
}
