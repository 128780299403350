import React, { useMemo } from "react";
import { STATUS, STATUSES_WITH_TOTAL_COUNTS } from "./status";
import cx from "classnames";

export default function AssessmentStatusPill({
  correctCount = 0,
  status = STATUS.UNKNOWN,
  totalCount = 0,
}) {
  const label = useMemo(() => {
    let value = STATUS[status.toUpperCase()];

    if (STATUSES_WITH_TOTAL_COUNTS.includes(status)) {
      value = `${value} - ${correctCount}/${totalCount}`;
    }

    return value;
  }, [correctCount, status, totalCount]);

  return (
    <span
      className={cx("text-xs rounded-full px-4 py-1 font-semibold uppercase", {
        "bg-yellow-100 text-yellow-800": status === STATUS.INCOMPLETE,
        "bg-gray-50 text-gray-800": status === STATUS.UNKNOWN,
        "bg-green-100 text-green-800": status === STATUS.COMPLETED || status === STATUS.PASSED,
        "bg-red-100 text-red-800": status === STATUS.FAILED,
      })}
    >
      {label}
    </span>
  );
}
