import React from "react";

import { useStudentLessonQuery } from "./queries";
import LessonRow from "./LessonRow";
import { Link } from "react-router-dom";
import AssessmentRow from "./AssessmentRow";

const ContentExpanded = ({ assessmentable }) => {
  const studentLessonQuery = useStudentLessonQuery({
    isBaseline: assessmentable.isBaseline,
    isOpen: assessmentable.isOpen,
    studentProtocolId: assessmentable.id,
  });

  if (!studentLessonQuery.data) {
    if (studentLessonQuery.isError) {
      return (
        <td className="flex items-center justify-center text-red-600 min-h-[200px]" colSpan={4}>
          Error: {studentLessonQuery.error.message}
        </td>
      );
    }

    return (
      <td className="flex items-center justify-center text-gray-400 min-h-[200px]" colSpan={4}>
        Loading...
      </td>
    );
  }

  const protocolScripts = assessmentable.protocolScripts || [];

  return (
    <td className="p-4 sm:p-6 lg:p-10" colSpan="4">
      <div className="mb-2 text-xs sm:text-sm text-zinc-500 space-y-4 leading-5">
        <p>
          <span className="font-medium text-zinc-900">Overview: </span>
          {assessmentable.description}
        </p>
        {!assessmentable.isBaseline ? (
          <p>
            {protocolScripts.length > 0 && (
              <span className="font-medium text-zinc-900">
                Script{protocolScripts.length > 1 && "s"}:{" "}
              </span>
            )}
            {protocolScripts.map((script, index) => (
              <span key={script.name}>
                <Link
                  to={script.url}
                  target="_blank"
                  title={script.name}
                  className="text-brand-600 hover:text-brand-900"
                >
                  {script.name}
                </Link>
                {index !== protocolScripts.length - 1 && ", "}
              </span>
            ))}
          </p>
        ) : null}
      </div>
      <table className="table-fixed border-collapse w-full text-xs sm:text-sm">
        <thead className="border-b border-zincs-300 hidden md:table-header-group">
          {assessmentable.isBaseline ||
          studentLessonQuery.data.filter((c) => {
            return c.isLesson;
          }).length === 0 ? (
            <tr>
              <th
                scope="col"
                className="py-3.5 pr-3 pl-0 sm:pl-3 text-left font-medium text-zinc-700 md:w-1/5"
                colSpan={5}
              >
                Assessment
              </th>
            </tr>
          ) : (
            <tr>
              <th
                scope="col"
                className="py-3.5 pr-3 pl-0 sm:pl-3 text-left font-medium text-zinc-700 md:w-1/5"
              >
                Lesson
              </th>
              <th scope="col" className="py-3.5 pr-3 text-left font-medium text-zinc-700 md:w-1/5">
                Content
              </th>
              <th scope="col" className="py-3.5 pr-3 font-medium text-zinc-700 md:w-1/5">
                Completions
              </th>
              <th scope="col" className="py-3.5 pr-3 font-medium text-zinc-700 md:w-1/5">
                HFW
              </th>
            </tr>
          )}
        </thead>
        <tbody>
          {studentLessonQuery.data.map((content) =>
            content.isLesson ? (
              <LessonRow
                content={content}
                key={`lesson-${content.id}`}
                isBaseline={assessmentable.isBaseline}
              />
            ) : (
              <AssessmentRow
                content={content}
                key={`assessment-${content.id}`}
                assessmentable={assessmentable}
              />
            )
          )}
        </tbody>
      </table>
    </td>
  );
};

export default ContentExpanded;
